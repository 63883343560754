* {
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

#root,
#root > div,
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #fcfcfc;
}

.overflow-y {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
  transition: 0.3s;
}
.cursor-pointer:hover {
  opacity: 0.7;
}
.cursor-pointer:active {
  opacity: 0.5;
}
