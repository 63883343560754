.auth {
  display: flex;
  height: 100%;
}

.auth_left {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  background-color: #2e3339;
  /* background-color: #ffb842; */
  padding: 4rem;
}

.auth_left_top {
  margin: auto 0;
  margin-top: 0;
}

.auth_left_top > img {
  max-width: 100%;
  height: 64px;
}

.auth_left_middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.auth_left_middle > h2 {
  font-size: 32px;
}

.auth_left_middle > h2,
.auth_left_middle > p {
  text-align: right;
  color: #ffffff;
}

.auth_left_bottom {
  margin: auto 0;
  margin-bottom: 0;
}

.auth_left_bottom > a {
  color: #ffffff;
  text-decoration: none;
}

.auth_left_background {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(85deg) scale(0.5, 0.75);
}

.auth_right {
  display: flex;
  width: 40%;
  height: 100%;
  padding: 4rem;
}

.auth_right_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .auth_left {
    display: none;
  }

  .auth_right {
    width: 100%;
    padding: 2rem;
  }
}
